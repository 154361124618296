import React, { Component } from 'react';
import { SmallLoadingComponent } from '../../common/loading_component';
import { PricingService } from '../../services/pricing_service';

const getTableBody = (data) => {
	if (!data) {
		return [];
	}
	return data.map(entry => {
		const nameString = entry.service_name.substring(0, 50);
		return (
			<tr>
				<td>{nameString}{nameString.length !== entry.service_name.length ? "..." : ""}</td>
				<td>{entry.active_year}</td>
				<td>{entry.service_price}€</td>
			</tr>
		)
	});
}

const QuatwayPricingTable = (props) => {
	return (
		<div>
			<h4>Adhésion annuelle</h4>
			<table className="table pricing-table">
				<thead className="thead-cng">
					<tr>
						<th scope="col">Dimensions</th>
						<th scope="col">Saison</th>
						<th scope="col">Tarif</th>
					</tr>
				</thead>
				<tbody>
					{getTableBody(props.data)}
				</tbody>
			</table>
			{props.isLoading ? <SmallLoadingComponent /> : <></>}
		</div>
	)
}



const TemporaryMemberPricingTable  = (props) => {
	return (
		<div>
			<h4>Adhésion temporaire</h4>
			<table className="table pricing-table">
				<thead className="thead-cng">
					<tr>
						<th scope="col">Type</th>
						<th scope="col">Saison</th>
						<th scope="col">Tarif</th>
					</tr>
				</thead>
				<tbody>
					{getTableBody(props.data)}
				</tbody>
			</table>
			{props.isLoading ? <SmallLoadingComponent /> : <></>}
		</div>
	)
}

const VariousPricingTable = (props) => {
	return (
		<div>
			<h4>Divers</h4>
			<table className="table pricing-table">
				<thead className="thead-cng">
					<tr>
						<th scope="col">Nom</th>
						<th scope="col">Saison</th>
						<th scope="col">Tarif</th>
					</tr>
				</thead>
				<tbody>
					{getTableBody(props.data)}
				</tbody>
			</table>
			{props.isLoading ? <SmallLoadingComponent /> : <></>}
		</div>
	)
}

const PricingTable = (props) => {
	return (
		<div>
			<h4>{props.category_display_name}</h4>

			<table className="table pricing-table">
				<thead className="thead-cng">
					<tr>
						<th scope="col">Nom</th>
						<th scope="col">Saison</th>
						<th scope="col">Tarif</th>
					</tr>
				</thead>
				<tbody>
					{getTableBody(props.services)}
				</tbody>
			</table>
		</div>
	)
}

export class PricingTables extends Component {

	constructor(props) {
		super(props);

		this.state = {
			pricings: {},
			isLoading: false,
			error: undefined
		} 
	}

	componentDidMount() {
		this.getPricings();
	}

	getPricings() {
		this.setState({isLoading: true}, () => {
			PricingService.getPricings((pricings) => {
				this.setState({pricings: pricings, isLoading: false, error: undefined});
			}, error => {
				this.setState({isLoading: false, error: error, pricings: {}});
			})
		});
	}

	render() {
		if (this.state.isLoading || this.state.pricings.availableServiceCategories === undefined) {
			return (<SmallLoadingComponent/>)
		}
		
		const availablePricings = this.state.pricings.availableServiceCategories.map(pricingCategory => {
			return (
				<div className="col-md-6 col-12">
					<PricingTable {...pricingCategory}/>
				</div>
			)
		})

		return (
			<div className="row">
				{availablePricings}
			</div>
		)
	}
}